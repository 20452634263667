<template>
    <div>
        <div class="row">
          <div class="col-xl-12 d-flex justify-content-end gutter-b">
            <span class="d-flex align-items-center mr-5">
              {{ $t("goodwemonitoring.title.last_sync") }} : <span class="font-weight-bolder ml-2 text-success">{{ grid.updated_at | moment("D.M.YYYY HH:mm:ss") }}</span>
            </span>
            <span class="btn btn-success" @click.prevent="resyncDevice" ref="resync_btn">
              <span class="svg-icon svg-icon-md svg-icon-white">
                <inline-svg src="/media/svg/icons/General/Update.svg" />
              </span>
              {{ $t("goodwemonitoring.button.sync") }} 
            </span>
            <router-link
                :to="{ name: 'goodwemonitoring_plant_detail', params: { id: grid.plant_id }}"
                class="btn btn-icon btn-primary btn-xxl ml-2"
                v-b-tooltip.bottom='$t("goodwemonitoring.meta.plant_detail")'
                >
                <span class="svg-icon svg-icon-md ">
                  <inline-svg src="/media/svg/icons/Code/Git4.svg" />
                </span>
              </router-link>
              <router-link
                :to="{ name: 'goodwemonitoring_grids'}"
                class="btn btn-outline-primary btn-xxl ml-2"
                >
                {{ $t("button.back") }}
              </router-link>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-6">
            <!--begin::Card-->
            <div class="card card-custom gutter-b card-stretch">
              <!--begin::Body-->
              <div class="card-body">
                <!--begin::Section-->
                <div class="d-flex align-items-center">
                  <!--begin::Pic-->
                  <div class="flex-shrink-0 mr-4 symbol symbol-50 symbol-circle">
                    <span class="svg-icon svg-icon-primary svg-icon-xxl">
                     <inline-svg class="svg-icon" src="/media/svg/icons/Code/Git2.svg" />
                    </span>
                  </div>
                  <!--end::Pic-->
                  <!--begin::Info-->
                  <div class="d-flex flex-column mr-auto">
                    <!--begin: Title-->
                    <span class="card-title font-weight-bolder font-size-h3 text-dark mb-1">{{ grid.model_type }}</span>
                    <span class="text-muted font-weight-bold">{{ grid.grid_id }}</span>
                    <!--end::Title-->
                  </div>
                  <!--end::Info-->
                  <div class="text">
                    <span v-if="grid.is_stored == 1"><small class="fas fa-check text-success pr-1"></small> {{ $t("goodwemonitoring.grid.label.hybird") }}</span>
                  </div>
                </div>
                <!--end::Section-->
                <!--begin::Content-->
                <div class="d-flex flex-wrap mt-8">
                  <div class="mr-12 d-flex flex-column mb-7">
                    <span class="d-block font-weight-bold mb-4">{{ $t("goodwemonitoring.batteries.label.status") }}</span>
                    <span v-if="grid.status == 1" class="btn btn-success btn-sm font-weight-bold btn-upper btn-text">{{ $t("goodwemonitoring.label.generating") }}</span>
                    <span v-if="grid.status == -1" class="btn btn-danger btn-sm font-weight-bold btn-upper btn-text">{{ $t("goodwemonitoring.label.offline") }}</span>
                    <span v-if="grid.status == 0" class="btn btn-warning btn-sm font-weight-bold btn-upper btn-text">{{ $t("goodwemonitoring.label.waiting") }}</span>
                    <span v-if="grid.status == 2" class="btn btn-danger btn-sm font-weight-bold btn-upper btn-text">{{ $t("goodwemonitoring.label.fault") }}</span>               
                  </div>

                  <div class="mr-12 d-flex flex-column mb-7">
                    <span class="d-block font-weight-bold mb-4">{{ $t("goodwemonitoring.grids.label.arm_version") }}</span>
                    <span class="btn btn-primary btn-sm font-weight-bold btn-upper btn-text">
                      <span v-if="grid.arm_version">{{ grid.arm_version }}</span>
                      <span v-else>-</span>
                    </span>            
                  </div>

                  <div class="mr-12 d-flex flex-column mb-7">
                    <span class="d-block font-weight-bold mb-4">{{ $t("goodwemonitoring.grids.label.temperature") }}</span>
                    <span class="btn btn-secondary btn-sm font-weight-bold btn-upper btn-text">{{ grid.inner_tempperature }}°</span>            
                  </div>
                  
                </div>

                <div class="d-flex flex-wrap mt-5">
                  <div class="mr-12 d-flex flex-column mb-7">
                      <span class="d-block font-weight-bold mb-4">{{ $t("goodwemonitoring.grids.label.capacity") }}</span>
                      <span class="btn btn-secondary btn-sm font-weight-bold btn-upper btn-text">{{ grid.capacity }} kW</span>
                  </div>

                  <div class="mr-12 d-flex flex-column mb-7">
                      <span class="d-block font-weight-bold mb-4">{{ $t("goodwemonitoring.grids.label.connected") }}</span>
                      <span class="btn btn-secondary btn-sm font-weight-bold btn-upper btn-text">{{ grid.connected | moment("D.M.YYYY HH:mm:ss") }}</span>
                  </div>

                  <div class="mr-12 d-flex flex-column mb-7">
                      <span class="d-block font-weight-bold mb-4">{{ $t("goodwemonitoring.grids.label.check_code") }}</span>
                      <span class="btn  btn-sm font-weight-bold btn-upper btn-text">{{ grid.check_code }}</span>
                  </div>

        
                </div>
                <!--end::Content-->
              </div>
              <!--end::Body-->
            </div>
            <!--end::Card-->
          </div>
          <div class="col-xl-6">
                          <!--begin::Card-->
            <div class="card card-custom gutter-b card-stretch">
                <!--begin::Body-->
                <div class="card-body">
                   <!--begin::Section-->
                    <div class="d-flex align-items-center">
                      <!--begin::Pic-->
                      <div class="flex-shrink-0 mr-4 symbol symbol-50 symbol-circle">
                        <span class="svg-icon svg-icon-primary svg-icon-xxl">
                        <inline-svg class="svg-icon" src="/media/svg/icons/Electric/Highvoltage.svg" />
                        </span>
                      </div>
                      <!--end::Pic-->
                      <!--begin::Info-->
                      <div class="d-flex flex-column mr-auto">
                        <!--begin: Title-->
                        <span class="card-title font-weight-bolder font-size-h5 text-dark mb-1">{{ $t("goodwemonitoring.grids.title.technic_informations") }}</span>
                    
                        <!--end::Title-->
                      </div>
                      <!--end::Info-->
                    </div>
                    <!--end::Section--> 


                    

                    <div class="d-flex align-items-center mt-2 px-3">
                      <div class="d-flex align-items-center flex-lg-fill mt-5 mb-2">
                        <span class="mr-4">
                          <i class="flaticon-interface-8 display-6 text-muted font-weight-bold"></i>
                        </span>
                        <div class="d-flex flex-column text-dark-75">
                          <span class="font-weight-bold font-size-sm">{{ $t("goodwemonitoring.grids.label.grid_power") }}</span>
                          <span class="font-weight-bolder font-size-h5" v-if="grid.grid_power">
                          <span class="text-dark-50 font-weight-bold"></span>{{ grid.grid_power }}</span>
                          <span v-else class="text-muted">-</span>
                        </div>
                      </div>

                      <div class="d-flex align-items-center flex-lg-fill mt-5 mb-2">
                        <span class="mr-4">
                          <i class="flaticon-interface-8 display-6 text-muted font-weight-bold"></i>
                        </span>
                        <div class="d-flex flex-column text-dark-75">
                          <span class="font-weight-bold font-size-sm">{{ $t("goodwemonitoring.grids.label.pv_power") }}</span>
                          <span class="font-weight-bolder font-size-h5" v-if="grid.pv_power">
                          <span class="text-dark-50 font-weight-bold"></span>{{ grid.pv_power }}</span>
                          <span v-else class="text-muted">-</span>
                        </div>
                      </div>
                    </div>

                    <div class="separator separator-solid"></div>

                    <div class="d-flex align-items-center mt-2 px-3">
                      <div class="d-flex align-items-center flex-lg-fill mt-5 mb-2">
                        <span class="mr-4">
                          <i class="flaticon-interface-8 display-6 text-muted font-weight-bold"></i>
                        </span>
                        <div class="d-flex flex-column text-dark-75">
                          <span class="font-weight-bold font-size-sm">{{ $t("goodwemonitoring.grids.label.hour_total") }}</span>
                          <span class="font-weight-bolder font-size-h5" v-if="grid.hour_total">
                          {{ grid.hour_total }} <span class="text-dark-50 font-weight-bold">h</span></span>
                          <span v-else class="text-muted">-</span>
                        </div>
                      </div>
                      <div class="d-flex align-items-center flex-lg-fill mt-5 mb-2">
                        <span class="mr-4">
                          <i class="flaticon-interface-8 display-6 text-muted font-weight-bold"></i>
                        </span>
                        <div class="d-flex flex-column text-dark-75">
                          <span class="font-weight-bold font-size-sm">{{ $t("goodwemonitoring.grids.label.total_generation") }}</span>
                          <span class="font-weight-bolder font-size-h5" v-if="grid.total_generation">
                          {{ grid.total_generation }} <span class="text-dark-50 font-weight-bold">kWh</span></span>
                          <span v-else class="text-muted">-</span>
                        </div>
                      </div>
                      <div class="d-flex align-items-center flex-lg-fill mt-5 mb-2">
                        <span class="mr-4">
                          <i class="flaticon-interface-8 display-6 text-muted font-weight-bold"></i>
                        </span>
                        <div class="d-flex flex-column text-dark-75">
                          <span class="font-weight-bold font-size-sm">{{ $t("goodwemonitoring.grids.label.daily_generation") }}</span>
                          <span class="font-weight-bolder font-size-h5" v-if="grid.daily_generation">
                          {{ grid.daily_generation }} <span class="text-dark-50 font-weight-bold">kWh</span></span>
                          <span v-else class="text-muted">-</span>
                        </div>
                      </div>
                      
                    </div>

                    <table class="table mt-4">
                    <tr>
                      <td>string_current1</td>
                      <td class="font-weight-bolder">
                          <span v-if="grid.string_current1 ">{{ grid.string_current1 }}</span>
                          <span v-else class="text-muted">-</span>
                      </td>
                      <td>string_current2</td>
                      <td class="font-weight-bolder">
                          <span v-if="grid.string_current2 ">{{ grid.string_current2 }}</span>
                          <span v-else class="text-muted">-</span>
                      </td>
                    </tr>
                    <tr>
                      <td>string_current3</td>
                      <td class="font-weight-bolder">
                          <span v-if="grid.string_current3 ">{{ grid.string_current3 }}</span>
                          <span v-else class="text-muted">-</span>
                      </td>
                      <td>string_current4</td>
                      <td class="font-weight-bolder">
                          <span v-if="grid.string_current4 ">{{ grid.string_current4 }}</span>
                          <span v-else class="text-muted">-</span>
                      </td>
                    </tr>
                  </table> 
                </div>
            </div>
          </div>
		</div>

    <div class="row">
    <div class="col-xl-6">
      <!--begin::Card-->
      <div class="card card-custom gutter-b card-stretch">
        <!--begin::Body-->
        <div class="card-body">
        <!--begin::Section-->
          <div class="d-flex align-items-center">
            <!--begin::Pic-->
            <div class="flex-shrink-0 mr-4 symbol symbol-50 symbol-circle">
              <span class="svg-icon svg-icon-primary svg-icon-xxl">
                <inline-svg class="svg-icon" src="/media/svg/icons/Code/Github.svg" />
              </span>
            </div>
            <!--end::Pic-->
            <!--begin::Info-->
            <div class="d-flex flex-column mr-auto">
              <!--begin: Title-->
              <span class="card-title font-weight-bolder font-size-h5 text-dark mb-1">{{ $t("goodwemonitoring.grids.title.ac_voltage") }}</span>
              <!--end::Title-->
            </div>
            <!--end::Info-->
          </div>
          <!--end::Section-->

          <table class="table mt-4">
            <tr>
              <td>ac_voltage_1</td>
              <td class="font-weight-bolder">
                  <span v-if="grid.ac_voltage_1 ">{{ grid.ac_voltage_1 }}</span>
                  <span v-else class="text-muted">-</span>
              </td>
            </tr>
            <tr>
              <td>ac_voltage_2</td>
              <td class="font-weight-bolder">
                  <span v-if="grid.ac_voltage_2 ">{{ grid.ac_voltage_2 }}</span>
                  <span v-else class="text-muted">-</span>
              </td>
            </tr>
            <tr>
              <td>ac_voltage_3</td>
              <td class="font-weight-bolder">
                  <span v-if="grid.ac_voltage_3 ">{{ grid.ac_voltage_3 }}</span>
                  <span v-else class="text-muted">-</span>
              </td>
            </tr>
            <tr>
              <td>ac_current_1</td>
              <td class="font-weight-bolder">
                  <span v-if="grid.ac_current_1 ">{{ grid.ac_current_1 }}</span>
                  <span v-else class="text-muted">-</span>
              </td>
            </tr>
            <tr>
              <td>ac_current_2</td>
              <td class="font-weight-bolder">
                  <span v-if="grid.ac_current_2 ">{{ grid.ac_current_2 }}</span>
                  <span v-else class="text-muted">-</span>
              </td>
            </tr>
            <tr>
              <td>ac_current_3</td>
              <td class="font-weight-bolder">
                  <span v-if="grid.ac_current_3 ">{{ grid.ac_current_3 }}</span>
                  <span v-else class="text-muted">-</span>
              </td>
            </tr>
            <tr>
              <td>ac_frequency_1</td>
              <td class="font-weight-bolder">
                  <span v-if="grid.ac_frequency_1 ">{{ grid.ac_frequency_1 }}</span>
                  <span v-else class="text-muted">-</span>
              </td>
            </tr>
            <tr>
              <td>ac_frequency_2</td>
              <td class="font-weight-bolder">
                  <span v-if="grid.ac_frequency_2 ">{{ grid.ac_frequency_2 }}</span>
                  <span v-else class="text-muted">-</span>
              </td>
            </tr>
            <tr>
              <td>ac_frequency_3</td>
              <td class="font-weight-bolder">
                  <span v-if="grid.ac_frequency_3 ">{{ grid.ac_frequency_3 }}</span>
                  <span v-else class="text-muted">-</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="col-xl-6">
      <!--begin::Card-->
      <div class="card card-custom gutter-b card-stretch">
        <!--begin::Body-->
        <div class="card-body">
            <!--begin::Section-->
            <div class="d-flex align-items-center">
              <!--begin::Pic-->
              <div class="flex-shrink-0 mr-4 symbol symbol-50 symbol-circle">
                <span class="svg-icon svg-icon-primary svg-icon-xxl">
                <inline-svg class="svg-icon" src="/media/svg/icons/Navigation/Arrows-h.svg" />
                </span>
              </div>
              <!--end::Pic-->
              <!--begin::Info-->
              <div class="d-flex flex-column mr-auto">
                <!--begin: Title-->
                <span class="card-title font-weight-bolder font-size-h5 text-dark mb-1">{{ $t("goodwemonitoring.grids.title.dc_voltage") }}</span>
                <!--end::Title-->
              </div>
              <!--end::Info-->
            </div>
            <!--end::Section-->
            <table class="table mt-4">
              <tr>
                <td>dc_voltage1_v</td>
                <td class="font-weight-bolder">
                    <span v-if="grid.dc_voltage1_v ">{{ grid.dc_voltage1_v }}</span>
                    <span v-else class="text-muted">-</span>
                </td>
              </tr>
              <tr>
                <td>dc_current1_a</td>
                <td class="font-weight-bolder">
                    <span v-if="grid.dc_current1_a ">{{ grid.dc_current1_a }}</span>
                    <span v-else class="text-muted">-</span>
                </td>
              </tr>
              <tr>
                <td>dc_voltage2_v</td>
                <td class="font-weight-bolder">
                    <span v-if="grid.dc_voltage2_v ">{{ grid.dc_voltage2_v }}</span>
                    <span v-else class="text-muted">-</span>
                </td>
              </tr>
              <tr>
                <td>dc_current2_a</td>
                <td class="font-weight-bolder">
                    <span v-if="grid.dc_current2_a ">{{ grid.dc_current2_a }}</span>
                    <span v-else class="text-muted">-</span>
                </td>
              </tr>
              <tr>
                <td>dc_voltage3_a</td>
                <td class="font-weight-bolder">
                    <span v-if="grid.dc_voltage3_a ">{{ grid.dc_voltage3_a }}</span>
                    <span v-else class="text-muted">-</span>
                </td>
              </tr>
              <tr>
                <td>dc_current3_a</td>
                <td class="font-weight-bolder">
                    <span v-if="grid.dc_current3_a ">{{ grid.dc_current3_a }}</span>
                    <span v-else class="text-muted">-</span>
                </td>
              </tr>
              <tr>
                <td>dc_voltage4_v</td>
                <td class="font-weight-bolder">
                    <span v-if="grid.dc_voltage4_v ">{{ grid.dc_voltage4_v }}</span>
                    <span v-else class="text-muted">-</span>
                </td>
              </tr>
              <tr>
                <td>dc_current4_a</td>
                <td class="font-weight-bolder">
                    <span v-if="grid.dc_current4_a ">{{ grid.dc_current4_a }}</span>
                    <span v-else class="text-muted">-</span>
                </td>
              </tr>

            </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12">
      <!--begin::Card-->
      <div class="card card-custom gutter-b card-stretch">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Section-->
          <div class="d-flex align-items-center">
            <!--begin::Pic-->
            <div class="flex-shrink-0 mr-4 symbol symbol-50 symbol-circle">
              <span class="svg-icon svg-icon-success svg-icon-xxl">
                <inline-svg class="svg-icon" src="/media/svg/icons/Code/Commit.svg" />
              </span>
            </div>
            <!--end::Pic-->
            <!--begin::Info-->
            <div class="d-flex flex-column mr-auto">
              <!--begin: Title-->
              <span class="card-title font-weight-bolder font-size-h5 text-dark mb-1">{{ $t("goodwemonitoring.grids.title.log_history") }}</span>
              <!--end::Title-->
            </div>
            <!--end::Info-->
          </div>
          <!--end::Section-->

            <perfect-scrollbar
                class="navi navi-hover scroll mt-4"
                style="max-height: 500px; position: relative;"
                v-if="grid_log.length > 0"
              >
                <table class="table table-head-custom table-head-bg table-vertical-center" >
                  <thead>
                    <tr class="text-left text-uppercase">
                      <th class="text-center">string_current1</th>
                      <th class="text-center">string_current2</th>
                      <th class="text-center">string_current3</th>
                      <th class="text-center">string_current4</th>
                      <th class="text-right">{{ $t("goodwemonitoring.grids.label.last_update") }}</th>
                    </tr>
                  </thead>
             
                  <tbody v-if="!loading">
                    <tr v-for="(log, index) in grid_log" :key="index">
                        <td align="left">
                          <span v-if="log.string_current1">{{log.string_current1}}</span>
                          <span v-else>-</span>
                        </td>
                        <td align="center">
                          <span v-if="log.string_current2">{{log.string_current2}}</span>
                          <span v-else>-</span>
                        </td>
                        <td align="center">
                          <span v-if="log.string_current3">{{log.string_current3}}</span>
                          <span v-else>-</span>
                        </td>
                        <td align="center">
                          <span v-if="log.string_current4">{{log.string_current4}}</span>
                          <span v-else>-</span>
                        </td>
                        <td align="right">
                          {{log.updated_at | moment("D.M.YYYY HH:mm:ss")}}
                        </td>
                    </tr>
                  </tbody>
                </table>
              </perfect-scrollbar>
              <p v-else>{{ $t("goodwemonitoring.grids.title.no_history_found") }}</p>

        </div>
      </div>
    </div>
  </div>

  <div class="row">
      <div class="col-xl-12">
        <!--begin::Card-->
        <div class="card card-custom gutter-b card-stretch">
          <!--begin::Body-->
          <div class="card-body">
            <!--begin::Section-->
            <div class="d-flex align-items-center">
              <!--begin::Pic-->
              <div class="flex-shrink-0 mr-4 symbol symbol-50 symbol-circle">
                <span class="svg-icon svg-icon-danger svg-icon-xxl">
                  <inline-svg class="svg-icon" src="/media/svg/icons/Code/Time-schedule.svg" />
                </span>
              </div>
              <!--end::Pic-->
              <!--begin::Info-->
              <div class="d-flex flex-column mr-auto">
                <!--begin: Title-->
                <span class="card-title font-weight-bolder font-size-h5 text-dark mb-1">{{ $t("goodwemonitoring.grids.title.error_history") }}</span>
                <!--end::Title-->
              </div>
              <!--end::Info-->
            </div>
            <!--end::Section-->
              <perfect-scrollbar
                class="navi navi-hover scroll mt-4"
                style="max-height: 500px; position: relative;"
                v-if="grid_conditions_log.length > 0"
              >
                <table class="table table-head-custom table-head-bg table-vertical-center" >
                  <thead>
                    <tr class="text-left text-uppercase">
                      <th class="text-left">{{ $t("goodwemonitoring.batteries.label.condition_name") }}</th>
                      <th class="text-right">{{ $t("goodwemonitoring.batteries.label.last_update") }}</th>
                    </tr>
                  </thead>
            
                  <tbody v-if="!loading">
                    <tr v-for="(filterlog, index) in grid_conditions_log" :key="index">
                        <td align="left">
                          <span v-if="filterlog.custom_filter">{{filterlog.custom_filter.name}}</span>
                          <span v-else>-</span>
                        </td>
                        <td align="right">
                          {{filterlog.updated_at | moment("D.M.YYYY HH:mm:ss")}}
                        </td>
                    </tr>
                  </tbody>
                </table>
              </perfect-scrollbar>
              <p v-else>{{ $t("goodwemonitoring.grids.title.no_history_found") }}</p>
          </div>
        </div>
      </div>
    </div>

    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  
  components: {

  },
  data() {
    return {
      grid: null,
      grid_log: null,
      grid_conditions_log: null,
    };
  },
  metaInfo () { 
      return { title: this.$t("goodwemonitoring.meta.grid_detail")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("goodwemonitoring.meta.devices"), subtitle: this.$t("goodwemonitoring.meta.grid_detail") }]);
    this.getGridDetail();
    this.getGridLog();
    this.getGridConditionLog();
  },
  methods: {
    getGridDetail() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/goodwe-monitoring/grids/" + this.$route.params.id)
        .then((response) => {
          this.grid = response.data.data;
          this.loading = false;
        });
    },
    getGridLog() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/goodwe-monitoring/grids/" + this.$route.params.id + "/data-logs?count=20")
        .then((response) => {
          this.grid_log = response.data.data;
          this.loading = false;
        });
    },
    getGridConditionLog() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/goodwe-monitoring/grids/" + this.$route.params.id + "/filter-logs?count=20")
        .then((response) => {
          this.grid_conditions_log = response.data.data;
          this.loading = false;
        });
    },
    resyncDevice() {
        // Loading spinner
      const submitButton = this.$refs["resync_btn"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.loading = true;

      ApiService.apiGet("/goodwe-monitoring/plants/" + this.grid.plant_id + "/resync")
        .then(() => {
            this.loading = false;
            submitButton.classList.remove("spinner","spinner-light","spinner-right");
            this.getGridDetail();
        });
    }
  }
};
</script>
